<template>
  <div
    v-bind:class="{
      'float-label': this.floatLabel,
      'float-active': this.floatActive,
    }"
    class="relative"
  >
    <label :class="labelClass" :for="id">{{ label }}</label>
    <div class="relative">
      <textarea
        :id="id"
        :value="value"
        :class="inputClasses"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        v-bind="$attrs"
      ></textarea>
      <pre
        v-show="placeholderActive"
        class="absolute placeholder inset-0"
        v-bind:class="{ 'font-display1': monospace, 'font-body': !monospace  }"
      >{{placeholder}}</pre>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToznyTextarea',
  props: {
    label: String,
    value: {
      type: String,
      default: '',
    },
    floatLabel: Boolean,
    id: {
      type: String,
      required: true,
    },
    placeholder: String,
    error: Boolean,
    success: Boolean,
    monospace: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
    }
  },
  inheritAttrs: false,
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    handleFocus() {
      this.focus = true
    },
    handleBlur() {
      this.focus = false
    },
  },
  computed: {
    floatActive() {
      return this.value !== '' || this.focus
    },
    placeholderActive() {
      if (!this.floatLabel) {
        return this.value === ''
      } else {
        return this.focus && this.value === ''
      }
    },
    inputClasses() {
      const classes = this.inputClass.split(' ')
      if (this.error) {
        classes.push('error')
      } else if (this.success) {
        classes.push('success')
      }
      if (this.monospace) {
        classes.push('font-display1')
      }
      return classes.join(' ')
    },
    labelClass() {
      return this.id.includes('secret') || this.id === 'description' ? '' : 'relative z-10'
    }
  },
}
</script>
<style scoped lang="scss">
@import '@/scss/tailwind-variables.scss';
.float-label {
  label {
    cursor: text;
    left: 0.5rem;
    top: 0.75rem;
    opacity: 0.3;
    background: $colors-white;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    padding: 0 0.1em;
    transition: all 0.2s ease;
  }
  &.float-active label {
    top: 0;
    opacity: 1;
    background: $colors-white;
    transform: translate(-10%, -50%) scale(0.8);
  }
}
</style>
