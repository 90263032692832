<template>
  <section>
    <ToznyInput
      data-auto-id="new-secret-name"
      v-model="value.secretName"
      class="flex-grow"
      name="secretName"
      id="secretName"
      label="Name"
      placeholder="Use something descriptive"
      :disabled="disabled"
    />
    <ToznyInput
      v-if="statusEditing"
      class="flex-grow mt-4"
      name="current-file"
      id="current-file"
      label="Current File"
      :placeholder="value.fileName"
      disabled="True"
    />
    <div class="mt-4 flex items-center">
      <form id="uploadForm" v-if="!statusViewing">
        <p>
          <label for="fileToUpload">{{ fileLabel }}</label>
          <input name="fileName" id="fileName" type="file"><br />
        </p>
      </form>
      <ToznyInput
        v-if="statusViewing"
        data-auto-id="new-secret-value"
        v-model="value.nameAndSize"
        class="flex-grow"
        name="fileName"
        id="fileName"
        label="File"
        :disabled="disabled"
      />
      <a
        v-if="statusViewing"
        :href="getFileUrl"
        :download="getDownloadStatus"
        class="color:black"
      >
      <span class="sr-only">Download the file.</span>
      <i
        class="flex-shrink-0 material-icons micon text-xl mx-4 mt-6 text-black"
        @click="handleChoose"
        >{{ icon }}</i
      >
      </a>
    </div>
  </section>
</template>

<script>
import ToznyInput from '@/Common/ToznyInput'
import { mapState } from 'vuex'

export default {
  name: 'CredentialForm',
  components: {
    ToznyInput,
  },
  data() {
    return {
      icon: 'get_app_outline',
      download: false, 
    }
  },
  props: {
    error: String,
    value: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    disabled: Boolean,
    changeableDisabled: Boolean,
  },
  computed: {
    ...mapState('secrets', ['status', 'fileUrl']),
    statusViewing() {
      return this.status !== 'manage.edit' && this.status !== 'manage.add' && this.status !== 'manage.error'
    },
    getFileUrl() {
      return this.fileUrl
    },
    getDownloadStatus() {
      return this.download
    },
    statusEditing() {
      return this.status === 'manage.edit'
    },
    fileLabel() {
      let label = this.status === 'manage.edit' ? 'Upload a New File' : 'Upload File'
      return label
    }
  },
  methods: {
    handleChoose() {
      this.download = this.value.fileName
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
}
</script>
