<template>
  <section>
    <ToznyInput
      data-auto-id="new-secret-name"
      v-model="value.secretName"
      class="flex-grow"
      name="secretName"
      id="secretName"
      label="Name"
      placeholder="Use something descriptive"
      :disabled="disableIdentifiers"
    />
    <div class="mt-4 flex items-center">
      <ToznyTextarea
        id="clientConfig"
        rows="12"
        class="flex-grow text-tiny"
        label="Client Credentials"
        :placeholder="credPlaceholder"
        v-model="value.secretValue"
        monospace
        :disabled="disabled"
      />
      <span class="sr-only">Click on the icon to copy the value.</span>
      <i
        v-if="copyEnabled"
        class="material-icons micon cursor-pointer ml-2 pt-6 text-green"
        @click="copyValue(value.secretValue)"
        >{{ icon }}</i
      >
    </div>
  </section>
</template>

<script>
import ToznyInput from '@/Common/ToznyInput'
import ToznyTextarea from '@/Common/ToznyTextarea'

export default {
  name: 'CredentialForm',
  components: {
    ToznyInput,
    ToznyTextarea,
  },
  data() {
    return {
      icon: 'content_copy',
      countdown: 2,
    }
  },
  computed: {
    credPlaceholder() {
      return `{
  "version": 2,
  "client_id": "3c63fb7c-6afa-4242-9902-b5e62a58f6c8",
  "client_email": "********************",
  "api_url": "********************",
  "api_key_id": "****************************************************************",
  "api_secret": "****************************************************************",
  "public_key": "*******************************************",
  "private_key": "*******************************************",
  "public_signing_key":  "*******************************************",
  "private_signing_key": "**************************************************************************************"
}`
    },
  },
  props: {
    error: String,
    value: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    disabled: Boolean,
    copyEnabled: Boolean,
    disableIdentifiers: Boolean,
  },
  methods: {
    async copyValue(s) {
      if (s.trim() !== '') {
        await navigator.clipboard.writeText(s)
        this.icon = 'library_add_check'
        this.countdownToChange()
      }
    },
    countdownToChange() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1
          this.countdownToChange()
          return
        }, 1000)
      }
      if (this.countdown === 0) {
        this.icon = 'content_copy'
        this.countdown = 3
      }
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
}
</script>
