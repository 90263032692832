<template>
  <section>
    <ToznyForm :on-submit="handleSubmit" :errors="error">
      <div class="m-4">
        <ToznySelect
          v-if="versionViewable"
          name="version"
          id="version"
          data-auto-id="version"
          label="Version"
          v-model="secretType"
          :value="versions[0]"
          :disabled="versionDisabled"
        >
          <option
            v-for="(version, index) in versions"
            v-bind:key="version"
            :value="version"
            :data-auto-id="`option${index}`"
          >
            {{ version }}
          </option>
        </ToznySelect>
        <div class="m-4">
          <div class="w-full">
            <ToznySelect
              class=""
              name="secretType"
              id="secretType"
              data-auto-id="secretType"
              label="Secret Type"
              v-model="secretType"
              :disabled="disabled"
            >
              <option value>Select a Secret Type</option>
              <option
                v-for="(type, index) in types"
                v-bind:key="type"
                :value="type"
                :data-auto-id="`option${index}`"
              >
                {{ type }}
              </option>
            </ToznySelect>
            <CredentialForm
              v-if="typeCredential"
              v-model="secret"
              :disabled="changeableDisabled"
              :disableIdentifiers="identifiersDisabled"
              :copyEnabled="copyEnabled"
            />
            <FileForm
              v-if="typeFile"
              v-model="secret"
              :disabled="disabled"
              :changeableDisabled="changeableDisabled"
            />
            <ClientForm
              v-if="typeClient"
              v-model="secret"
              :disabled="changeableDisabled"
              :disableIdentifiers="identifiersDisabled"
              :copyEnabled="copyEnabled"
            />
          </div>
          <section v-if="typeSelected">
            <div class="w-full">
              <ToznyTextarea
                id="description"
                rows="4"
                class="mb4"
                label="Description"
                :placeholder="descriptionValue"
                v-model="description"
                monospace
                :disabled="changeableDisabled"
              />
              <div
                v-if="statusAddErrors"
                class="text-error text-tiny text-center mb-4"
              >
                <p>{{ this.errorMessage }}</p>
              </div>
              <AddShare
                v-if="statusAddShare"
                @cancel="transitionStatus('manage.view')"
              />
              <ManageShareList
                v-if="identifiersDisabled"
                :username="getSharedUsernameList()"
                id="sharedWith"
                class="mb4"
                v-model="shareWith"
                monospace
                @delete="confirmRemove"
              />
            </div>
            <div class="flex flex-row">
              <ToznyButton
                v-if="identifiersDisabled"
                id="addShare"
                data-auto-id="share-secret-submit"
                buttonText="Share With User"
                class="tozny-theme-button rounded-lg mr-4"
                @click.native="transitionStatus('manage.addShare')"
              />
              <RemoveShareDialogueBox
                :showing="showRemoveDialogue"
                :identity="secret"
                @confirm="revokeShare"
                @cancel="removeFromRevoke"
              />
            </div>
            <div v-if="statusDelete">
              <DeleteSingleSecretDialogue
                :secret="secret"
                :version="versions[0]"
                :showing="statusDelete"
                @confirm="handleDeleteSingle"
                @cancel="handleCancel"
              />
            </div>
            <div class="mt-4 flex flex-row-reverse items-center">
              <ToznyButton
                id="new-secret-submit"
                data-auto-id="new-secret-submit"
                class="m-0"
                :buttonText="buttonText"
                :loading="loading"
              />
              <a
                href="#!"
                @click.prevent="handleCancel"
                class="text-gray-darkest no-underline mr-8"
                >Cancel</a
              >
            </div>
          </section>
        </div>
      </div>
    </ToznyForm>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { format } from 'date-fns'
import ToznyForm from '@/Common/ToznyForm'
import ToznySelect from '@/Common/ToznySelect'
import ToznyTextarea from '@/Common/ToznyTextarea'
import ToznyButton from '@/Common/ToznyButton'
import CredentialForm from './CredentialForm'
import RemoveShareDialogueBox from './RemoveShareDialogueBox'
import AddShare from './AddShare'
import ManageShareList from './ManageShareList.vue'
import FileForm from './FileForm'
import ClientForm from './ClientForm'
import DeleteSingleSecretDialogue from './DeleteSingleSecretDialogue'

export default {
  name: 'SecretData',
  components: {
    ToznyForm,
    ToznySelect,
    ToznyTextarea,
    ToznyButton,
    CredentialForm,
    RemoveShareDialogueBox,
    AddShare,
    ManageShareList,
    FileForm,
    ClientForm,
    DeleteSingleSecretDialogue,
  },
  props: {
    error: String,
    loading: Boolean,
    disabled: Boolean,
    success: String,
    buttonText: String,
    types: Array,
  },
  data() {
    return {
      versions: [],
      secretType: '',
      secret: {
        secretName: '',
        secretValue: '',
        fileName: '',
        nameAndSize: '',
      },
      description: '',
      shareWith: '',
    }
  },
  computed: {
    ...mapState('secrets', [
      'status',
      'currentSecret',
      'shareList',
      'errorMessage',
    ]),
    statusErrors() {
      return this.error !== '' || this.errorMessage !== ''
    },
    addSuccess() {
      return this.success !== ''
    },
    typeCredential() {
      return this.secretType === 'Credential'
    },
    typeFile() {
      return this.secretType === 'File'
    },
    typeClient() {
      return this.secretType === 'Client'
    },
    typeSelected() {
      return this.secretType !== ''
    },
    secretIDParam() {
      return this.$route.params.recordId
    },
    descriptionValue() {
      return this.disabled ? '' : 'Describe the secret'
    },
    identifiersDisabled() {
      return (
        this.status === 'manage.view' ||
        this.status === 'manage.edit' ||
        this.status === 'manage.addShare'
      )
    },
    changeableDisabled() {
      return this.status === 'manage.view' || this.status === 'manage.addShare'
    },
    copyEnabled() {
      return (
        this.status !== 'manage.add' &&
        this.status !== 'manage.edit' &&
        this.status !== 'manage.addShare'
      )
    },
    statusAddable() {
      return this.status !== 'manage.addShare'
    },
    statusAddShare() {
      return this.status === 'manage.addShare'
    },
    statusAddErrors() {
      return (this.error !== '' || this.errorMessage !== '') && this.status === 'manage.addShare'
    },
    showRemoveDialogue() {
      return this.status === 'manage.removeShare'
    },
    versionViewable() {
      return this.status !== 'manage.add'
    },
    statusDelete() {
      return this.status === 'manage.delete'
    },
    versionDisabled() {
      return this.status !== 'manage.view' && this.status !== 'idle'
    }
  },
  methods: {
    ...mapActions('secrets', [
      'createSecret',
      'transitionStatus',
      'initialize',
      'initializeSecret',
      'updateSecret',
      'revokeShare',
      'getShareList',
      'setToRevoke',
      'setToDelete',
      'removeFromRevoke',
      'deleteSingleSecret',
    ]),
    async handleSubmit() {
      if (this.status === 'manage.view') {
        await this.transitionStatus('manage.edit')
      } else if (this.status === 'manage.edit') {
        let selectedFile = ''
        if (this.secretType === 'File') {
          const form = document.getElementById('uploadForm')
          const formData = new FormData(form)
          selectedFile = formData.get('fileName')
        }
        let value = this.secret.secretValue === undefined ? '' : this.secret.secretValue
        const newSecret = {
          secretType: this.secretType,
          secretName: this.secret.secretName,
          secretValue: value,
          fileName: selectedFile.name,
          file: selectedFile,
          description: this.description,
        }
        await this.updateSecret(newSecret)
        if (this.error === '') {
          this.$router.push(
            `/${this.$route.params.realmName}/secrets/${this.currentSecret.meta.recordId}`
          )
        }
      } else if (this.status === 'manage.add') {
        let selectedFile = ''
        if (this.secretType === 'File') {
          const form = document.getElementById('uploadForm')
          const formData = new FormData(form)
          selectedFile = formData.get('fileName')
        }
        let value = this.secret.secretValue === undefined ? '' : this.secret.secretValue
        const secretObj = {
          secretType: this.secretType,
          secretName: this.secret.secretName,
          secretValue: value,
          fileName: selectedFile.name,
          file: selectedFile,
          description: this.description,
        }
        await this.createSecret(secretObj)
        if (this.error === '') {
          this.$router.push(
            `/${this.$route.params.realmName}/secrets/${this.currentSecret.meta.recordId}`
          )
        }
      } else {
        await this.transitionStatus('manage.addShare')
      }
    },
    async initialize() {
      if (this.secretIDParam && this.status !== 'manage.add') {
        await this.initializeSecret(this.secretIDParam)
        if (this.currentSecret) {
          this.versions = []
          this.secretType = this.currentSecret.meta.plain.secretType
          this.secret.secretName = this.currentSecret.meta.plain.secretName
          this.secret.secretValue = this.currentSecret.data.secretValue
          this.description = this.currentSecret.meta.plain.description
          this.shareWith = this.shareList
          if (this.secretType === 'File') {
            this.secret.fileName = this.currentSecret.meta.plain.fileName
            this.secret.nameAndSize = `${this.secret.fileName} (${this.currentSecret.meta.plain.size} KB)`
          }
          const time = format(
            this.currentSecret.meta.created,
            'MMM d, yyyy h:mm a'
          )
          this.versions[0] = time
        }
      }
    },
    handleCancel() {
      if (this.status === 'manage.edit' || this.status === 'manage.delete') {
        this.transitionStatus('manage.view')
      } else {
        this.$emit('cancel')
      }
    },
    async handleDeleteSingle() {
      await this.deleteSingleSecret()
      this.$router.push(`/${this.$route.params.realmName}/secrets`)
    },
    async confirmRemove(usernameToDelete) {
      this.setToRevoke(usernameToDelete)
    },
    getSharedUsernameList() {
      return this.shareList.map((share) => ({
        username: share,
      }))
    },
  },
  created: async function () {
    await this.initialize()
  },
  watch: {
    secretIDParam() {
      return this.initialize()
    },
    status(newStat) {
      if (newStat === 'manage.add') {
        this.versions = []
        this.secretType = ''
        this.secret.secretName = ''
        this.secret.secretValue = ''
        this.description = ''
        this.shareWith = ''
        this.secret.fileName = ''
      }
    },
  },
}
</script>
