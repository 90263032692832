<template>
  <section>
    <ToznyInput
      data-auto-id="new-secret-name"
      v-model="value.secretName"
      class="flex-grow"
      name="secretName"
      id="secretName"
      label="Name"
      placeholder="Use something descriptive"
      :disabled="disableIdentifiers"
    />
    <div class="mt-4 flex items-center">
      <ToznyInput
        data-auto-id="new-secret-value"
        v-model="value.secretValue"
        class="flex-grow"
        name="secretValue"
        id="secretValue"
        label="Value"
        placeholder="secretvalue"
        :disabled="disabled"
        type="password"
      />
      <span class="sr-only">Click on the icon to copy the value.</span>
      <i
        v-if="copyEnabled"
        class="material-icons micon cursor-pointer ml-2 pt-6 text-green"
        @click="copyValue(value.secretValue)"
        >{{ icon }}</i
      >
    </div>
  </section>
</template>

<script>
import ToznyInput from '@/Common/ToznyInput'

export default {
  name: 'CredentialForm',
  components: {
    ToznyInput,
  },
  data() {
    return {
      icon: 'content_copy',
      countdown: 2,
    }
  },
  props: {
    error: String,
    value: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    disabled: Boolean,
    copyEnabled: Boolean,
    disableIdentifiers: Boolean,
  },
  methods: {
    async copyValue(s) {
      if (s.trim() !== '') {
        await navigator.clipboard.writeText(s)
        this.icon = 'library_add_check'
        this.countdownToChange()
      }
    },
    countdownToChange() {
      if(this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1
          this.countdownToChange()
          return
        }, 1000)
      }
      if(this.countdown === 0) {
        this.icon = 'content_copy'
        this.countdown = 3
      }
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
}
</script>
