<template>
  <MainLayout>
    <section class="grid grid-cols-2 grid-rows-auto gap-4">
      <div>
        <ListSecrets
          :v-if="reloadSecrets"
          :secrets="listSecrets()"
          :error="errorMessage"
          :has-more="hasMore"
          @more="getSecrets"
        />
      </div>
      <div>
        <ManageSecrets
          :errorMessage="errorMessage"
          :successMessage="successMessage"
          @cancel="handleCancel"
          @submit="createSecret"
        />
      </div>
    </section>
  </MainLayout>
</template>

<script>
import MainLayout from '@/Common/MainLayout/MainLayout'
import ListSecrets from './Components/ListSecrets'
import ManageSecrets from './Components/ManageSecrets'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Secrets',
  components: {
    MainLayout,
    ListSecrets,
    ManageSecrets,
  },
  computed: {
    ...mapState('secrets', [
      'status',
      'errorMessage',
      'successMessage',
      'secrets',
      'currentUser',
      'shareList',
    ]),
    ...mapGetters('secrets', ['hasMore']),
    reloadSecrets() {
      return this.status === 'idle' || this.status === 'manage.view'
    },
    secretLoading() {
      return this.status === 'manage.loading'
    },
  },
  methods: {
    ...mapActions('secrets', [
      'initializeSecrets',
      'getSecrets',
      'transitionStatus',
      'clearMessage',
      'createSecret',
    ]),
    handleCancel() {
      this.clearMessage()
      if (this.$route.name === 'single-secret') {
        this.$router.push(`/${this.$route.params.realmName}/secrets`)
      }
      this.transitionStatus('idle')
    },
    listSecrets() {
      let secretList = []
      if (this.secrets) {
        this.secrets.forEach((secret) => {
          let rawData
          if (secret.meta['userId'] === this.currentUser) {
            rawData = {
              name: secret.meta.plain['secretName'],
              type: secret.meta.plain['secretType'],
              userId: 'You',
              shared: secret.meta['shared'],
              secretId: secret.meta.recordId,
            }
          } else {
            rawData = {
              name: secret.meta.plain['secretName'],
              type: secret.meta.plain['secretType'],
              userId: secret.meta['username'],
              shared: secret.meta['shared'],
              secretId: secret.meta.recordId,
            }
          }
          secretList.push(rawData)
        })
      }

      return secretList
    },
  },
  created: async function () {
    await this.initializeSecrets()
  },
}
</script>
