<template>
  <DialogueBox
    data-auto-id="remove-share-dialogue"
    :showing="showing"
    title="Confirm Deletion"
  >
    <div class="p-4">
      <p>
        Are you sure you want to remove this user?
        <br />
        The user will not be able to view this secret anymore.
      </p>
      <dl class="mb-8">
        <dt data-auto-id="remove-share-name" class="font-bold">
          {{ identity.username }}
        </dt>
      </dl>
      <div class="flex items-center">
        <button
          data-auto-id="remove-share-confirm"
          @click.prevent="$emit('confirm')"
          class="m-0 mr-4"
        >
          Confirm
        </button>
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline"
          >Cancel</a
        >
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '@/Common/DialogueBox'
export default {
  name: 'RemoveShareDialogueBox',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    identity: Object,
  },
}
</script>
