<template>
  <ContentCard title="Manage a Secret">
    <template #extras>
      <Actions
        :actions="cardActions"
        @action="handleCardActions"
        auto-id="secret-card"
      />
    </template>
    <div class="pt-2 pb-2">
      <div v-if="statusError" class="text-error text-tiny text-center mb-4">
        <p>{{ errorMessage }}</p>
      </div>
      <div v-if="addSuccess" class="text-tiny text-success text-center mb-4">
        {{ successMessage }}
      </div>
      <div v-if="statusWaiting" class="m-6">
        <span class="flex justify-center"
          >Click on a secret to view or edit it.</span
        >
        <span class="flex justify-center"
          >Or click the button to add a secret.</span
        >
        <div class="flex justify-center">
          <ToznyButton
            data-auto-id="show-secret-form"
            class="m-4 flex justify center"
            @click.native="transitionStatus('manage.add')"
            buttonText="Add a Secret"
          />
        </div>
      </div>
      <SecretData
        v-if="statusManage"
        @cancel="$emit('cancel')"
        :error="errorMessage"
        :success="successMessage"
        :disabled="statusDisabled"
        :loading="statusLoading"
        :buttonText="addOrEdit"
        :types="types"
      />
    </div>
  </ContentCard>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContentCard from '@/Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
import SecretData from './SecretData'
import Actions from '@/Common/Actions'
export default {
  name: 'ManageSecrets',
  components: {
    ContentCard,
    ToznyButton,
    SecretData,
    Actions,
  },
  props: {
    errorMessage: String,
    successMessage: String,
  },
  computed: {
    ...mapState('secrets', ['status', 'types']),
    statusLoading() {
      return this.status === 'manage.loading'
    },
    statusWaiting() {
      return (
        this.$route.params.recordId === undefined &&
        (this.status === 'idle' ||
          this.status === 'initializing' ||
          this.status === 'loading')
      )
    },
    statusManage() {
      return (
        this.status === 'manage.add' ||
        this.status === 'manage.view' ||
        this.status === 'manage.error' ||
        this.$route.params.recordId
      )
    },
    statusDisabled() {
      return (
        this.status === 'manage.view' ||
        (this.$route.params.recordId !== undefined &&
          this.status !== 'manage.add' && this.status !== 'manage.error')
      )
    },
    statusViewing() {
      return this.status === 'manage.edit' || this.status === 'manage.view'
    },
    statusError() {
      return this.errorMessage !== ''
    },
    addSuccess() {
      return this.successMessage !== '' && this.status === 'manage.view'
    },
    addOrEdit() {
      if (this.status === 'manage.add') {
        return 'Create a Secret'
      } else {
        return 'Edit Secret'
      }
    },
    cardActions() {
      const edit = {
        event: 'edit',
        altText: 'edit secret',
        icon: 'edit-outline',
      }
      const add = {
        event: 'add',
        altText: 'add secret',
        icon: 'add-outline',
      }
      const deleteAction = {
        event: 'delete',
        altText: 'Delete Secret',
        icon: 'delete',
      }
      if (
        this.$route.path !== `/${this.$route.params.realmName}/secrets` &&
        this.status !== 'manage.edit' &&
        this.status !== 'manage.add'
      ) {
        return [deleteAction, edit, add]
      } else if (this.status === 'manage.edit') {
        return [deleteAction, add]
      } else {
        return [add]
      }
    },
  },
  methods: {
    ...mapActions('secrets', ['transitionStatus']),
    async handleCardActions(action) {
      if (action.event === 'add') {
        await this.transitionStatus('manage.add')
      } else if (action.event === 'delete') {
        await this.transitionStatus('manage.delete')
      } else {
        await this.transitionStatus('manage.edit')
      }
    },
  },
}
</script>
