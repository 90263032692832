<template>
  <DialogueBox
    data-auto-id="delete-secret-dialogue"
    :showing="showing"
    title="Confirm Deletion"
  >
    <div class="p-4">
      <p>
        Are you sure you want to delete this version of {{ secret.secretName }}?
        <br />
        <br />
        This version of the secret will be permanently deleted and will no longer be accessible to anyone it was shared with.
      </p>
      <dl class="mb-8">
        <dt data-auto-id="remove-share-name" class="font-bold">
          Secret Name: {{ secret.secretName }}
          <br />
          Created: {{ version }}
        </dt>
      </dl>
      <div class="flex items-center">
        <button
          data-auto-id="remove-share-confirm"
          @click.prevent="$emit('confirm')"
          class="m-0 mr-4"
        >
          Confirm
        </button>
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline"
          >Cancel</a
        >
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '@/Common/DialogueBox'
export default {
  name: 'DeleteSingleSecretDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    secret: Object,
    version: String,
  },
}
</script>
