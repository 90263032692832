<template>
  <div class="relative" :class="floatLabel ? 'float-label' : ''">
    <label :for="id">{{ label }}</label>
    <select
      v-bind:class="{ error: error, success: success }"
      :id="id"
      :value="value"
      @change="handleChange"
      v-bind="$attrs"
    >
      <slot />
    </select>
  </div>
</template>
<script>
export default {
  name: 'ToznySelect',
  props: {
    label: String,
    id: {
      type: String,
      required: true,
    },
    error: Boolean,
    success: Boolean,
    value: String,
    floatLabel: {
      type: Boolean,
      default: false,
    }
  },
  inheritAttrs: false,
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value)
    },
  },
}
</script>

<style scoped lang="scss">
  @import '@/scss/tailwind-variables.scss';
  .float-label label {
    cursor: text;
    left: 0.5rem;
    top: 50%;
    background: $colors-white;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    padding: 0 0.1em;
    top: 0;
    opacity: 1;
    transform: translate(-10%, -50%) scale(0.8);
  }
</style>
