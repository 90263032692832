<template>
  <section>
    <ContentCard data-auto-id="add-list" title="Add Share">
      <div v-if="statusError" class="text-error text-tiny text-center mb-4">
        <p>{{ this.errorMessage }}</p>
      </div>
      <ToznyForm
        :on-submit="handleSubmit"
        :loading="loading"
        class="h-full w-full flex flex-col justify-between compact"
      >
        <div class="flex-shrink-0 flex-grow-0 pt-2 mb-4 px-4">
          <ToznyInput
            data-auto-id="share-username"
            v-model="username"
            class="flex-grow"
            name="username"
            id="username"
            label="Enter a username for an identity in your realm: "
            placeholder="Username"
          />
          <div class="flex flex-row-reverse items-center">
            <ToznyButton
              id="new-share-submit"
              data-auto-id="new-share-submit"
              class="m-3"
              :buttonText="'Share with User'"
              :loading="loading"
            />
            <a
              href="#!"
              @click.prevent="$emit('cancel')"
              class="text-gray-darkest no-underline mr-4"
              >Cancel</a
            >
          </div>
        </div>
      </ToznyForm>
    </ContentCard>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ContentCard from '@/Common/ContentCard'

export default {
  name: 'AddShare',
  components: {
    ToznyForm,
    ToznyButton,
    ContentCard,
    ToznyInput,
  },
  props: {
    error: String,
    loading: Boolean,
    disabled: Boolean,
    copyEnabled: Boolean,
    disableIdentifiers: Boolean,
  },
  data() {
    return {
      username: '',
    }
  },
  computed: {
    ...mapState('secrets', ['status', 'currentSecret', 'errorMessage']),
    statusError() {
      return this.error !== ''
    },
  },
  methods: {
    ...mapActions('secrets', ['shareSecretWithUsername']),
    async handleSubmit() {
      await this.shareSecretWithUsername(this.username)
    },
  },
}
</script>
