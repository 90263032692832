<template>
  <Transition name="fade">
    <div
      v-if="showing"
      class="fixed inset-0 w-full h-screen flex items-center justify-center popover-bg"
    >
      <div class="relative w-full max-w-lg bg-white shadow-md">
        <div :class="bgClass" class="text-white p-4 flex">
          <div class="flex items-center mr-4">
            <i class="material-icons-outlined md-24 micon">{{ icon }}</i>
          </div>
          <div>
            <h4 class="font-normal">{{ title }}</h4>
            <slot name="header" />
          </div>
        </div>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    showing: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      default: 'error',
    },
    title: String,
    color: {
      type: String,
      default: 'error',
      validator: c =>
        ['tozny', 'tozstore', 'tozid', 'success', 'error'].includes(c),
    },
  },
  computed: {
    bgClass() {
      // do the class as full strings to trigger purgecss correctly
      switch (this.color) {
        case 'tozny':
          return 'bg-tozny'
        case 'tozstore':
          return 'bg-tozstore'
        case 'tozid':
          return 'bg-tozid'
        case 'success':
          return 'bg-success'
        default:
          return 'bg-error'
      }
    },
  },
  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }

      document.querySelector('body').classList.remove('overflow-hidden')
    },
  },
}
</script>

<style scoped lang="scss">
.popover-bg {
  background: rgba(47, 53, 58, 0.4);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
