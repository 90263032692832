<template>
  <div class="pt-8 px-4 pb-4">
    <ItemTable
      data-auto-id="share-table"
      :items="username"
      :columns="tableColumns"
      class="cursor-pointer"
    >
      <template #actions="{ itemData }">
        <Actions
          :actions="actionObject(itemData)"
          @action="handleAction"
          :auto-id="`share-actions-${itemData}`"
        />
      </template>
    </ItemTable>
  </div>
</template>

<script>
import ItemTable from '@/Common/ItemTable'
import Actions from '@/Common/Actions'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'ShareList',
  components: {
    ItemTable,
    Actions,
  },
  data: () => ({}),
  props: {
    username: {
      type: Array,
      required: true,
    },
    errorMessage: String,
  },
  computed: {
    ...mapState('secrets', ['status']),
    ...mapGetters('secrets', ['getSecret']),
    tableColumns() {
      return [
        {
          title: 'Shared',
          target: 'username',
          slot: 'username',
        },
        {
          title: 'Remove',
          target: 'username',
          slot: 'actions',
          className: 'whitespace-no-wrap w-px text-center',
        },
      ]
    },
  },
  methods: {
    ...mapActions('secrets', ['clearMessage']),
    ...mapGetters('secrets', ['secretID']),
    handleAction(action) {
      this.$emit(action.event, action.usernameToDelete)
    },
    actionObject(username) {
      return [
        {
          usernameToDelete: username,
          event: 'delete',
          altText: 'delete share',
          icon: 'delete-outline',
        },
      ]
    },
  },
}
</script>
