<template>
  <ContentCard title="Secrets">
    <div class="pt-8 px-4 pb-4">
      <ItemTable
        data-auto-id="secrets-table"
        :has-more="hasMore"
        :items="secrets"
        :columns="tableColumns"
        @more="$emit('more')"
        @rowClick="handleRowClick"
        class="cursor-pointer"
      >
      </ItemTable>
    </div>
  </ContentCard>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ItemTable from '@/Common/ItemTable'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'SecretsList',
  components: {
    ContentCard,
    ItemTable,
  },
  data: () => ({}),
  props: {
    secrets: {
      type: Array,
      default: () => [],
      validator(items) {
        if (items == null) {
          return false
        }
        return true
      },
    },
    errorMessage: String,
    hasMore: Boolean,
  },
  computed: {
    ...mapState('secrets', ['status', 'shareList']),
    ...mapGetters('secrets', ['getSecret']),
    tableColumns() {
      return [
        {
          title: 'Name',
          target: 'name',
          slot: 'secretName',
        },
        {
          title: 'Type',
          target: 'type',
          slot: 'type',
        },
        {
          title: 'Owner',
          target: 'userId',
          slot: 'owner',
        },
        {
          title: 'Shared',
          target: 'shared',
          slot: 'shared',
        },
      ]
    },
  },
  methods: {
    ...mapActions('secrets', ['initializeSecret', 'clearMessage']),
    ...mapGetters('secrets', ['secretID']),
    async handleRowClick(client) {
      this.clearMessage()
      this.$router.push(
        `/${this.$route.params.realmName}/secrets/${client.secretId}`
      )
    },
  },
  watch: {
    '$route.params.recordId': {
      handler: function (secretID) {
        this.initializeSecret(secretID)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
